const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6936863",
    space_js: "//sfzpjbt.jtszjs.com/site/tonw-gn/common/q/resource/n-g.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6936864",
    space_js: "//sfzpjbt.jtszjs.com/source/upox_h/o_ro/static/l.js"
  }
];

const NativeSm1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6936865",
    space_js: "//sfzpjbt.jtszjs.com/site/vq/openjs/py/production/i-ps-pn.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: NativeSm1,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: NativeSm1,
  List_native: Native1,
  List_banner: NativeSm1,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: NativeSm1
};
